import React, {createContext, useEffect, useState} from 'react'
import { useAuth} from '../../auth'
import { FilterOptionsModel } from '../../statistics/Core/models'
import { getEmployeesInGroup, getGroupsInGroup, getSectionsInGroup, getSpacesInGroup } from './_requests'

export type SpaceGroupContextType = {
  spacesInGroup: FilterOptionsModel[]
  setSpacesInGroup: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  employesInGroup: FilterOptionsModel[]
  setEmployesInGroup: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  sectionsInGroup: FilterOptionsModel[]
  setSectionsInGroup: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
  groupsInGroup: FilterOptionsModel[]
  setGroupsInGroup: React.Dispatch<React.SetStateAction<FilterOptionsModel[]>>
}

export const SpaceGroupContext = createContext<SpaceGroupContextType | undefined>(undefined)

interface SpaceGroupProviderProps {
  children: React.ReactNode
}

const SpaceGroupProvider: React.FC<SpaceGroupProviderProps> = ({children}) => {
  const {currentSpaceGroup} = useAuth()
  const [spacesInGroup, setSpacesInGroup] = useState<FilterOptionsModel[]>([])
  const [employesInGroup, setEmployesInGroup] = useState<FilterOptionsModel[]>([])
  const [sectionsInGroup, setSectionsInGroup] = useState<FilterOptionsModel[]>([])
  const [groupsInGroup, setGroupsInGroup] = useState<FilterOptionsModel[]>([])
  
  useEffect(() => {
    if(currentSpaceGroup && currentSpaceGroup.spaceGroupId){
      getSpacesInGroup(currentSpaceGroup?.spaceGroupId).then((x)=>{
        if(x.data)
          setSpacesInGroup(x.data)
      })
      getEmployeesInGroup(currentSpaceGroup?.spaceGroupId).then((x)=>{
        if(x.data)
          setEmployesInGroup(x.data)
      })
      getSectionsInGroup(currentSpaceGroup?.spaceGroupId).then((x)=>{
        if(x.data)
          setSectionsInGroup(x.data)
      })
      getGroupsInGroup(currentSpaceGroup?.spaceGroupId).then((x)=>{
        if(x.data)
          setGroupsInGroup(x.data)
      })
    }else{
      setSpacesInGroup([])
      setEmployesInGroup([])
      setSectionsInGroup([])
      setGroupsInGroup([])
    }
    
  }, [currentSpaceGroup])
  

  return (
    <SpaceGroupContext.Provider
      value={{
        spacesInGroup,
        setSpacesInGroup,
        employesInGroup,
        setEmployesInGroup,
        sectionsInGroup,
        setSectionsInGroup,
        groupsInGroup,
        setGroupsInGroup
      } as SpaceGroupContextType}
    >
      {children}
    </SpaceGroupContext.Provider>
  )
}

export default SpaceGroupProvider
