import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {AuthModel, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import * as spaceHelper from './SpaceHelpers'
import {getUserByToken} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {Space, SpaceGroup} from '../../spaces/spaces-list/core/_models'
import {getCountriesBySpace} from '../../country/components/country/core/_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
  currentSpaceGroup: SpaceGroup | undefined
  saveSpaceGroup: (space: SpaceGroup | undefined) => void
  currentSpace: Space | undefined
  saveSpace: (space: Space | undefined) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
  currentSpaceGroup: spaceHelper.getSpaceGroup(),
  currentSpace: spaceHelper.getSpace(),
  saveSpace: () => {},
  saveSpaceGroup: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentSpaceGroup, setCurrentSpaceGroup] = useState<SpaceGroup | undefined>(
    spaceHelper.getSpaceGroup()
  )
  const [currentSpace, setCurrentSpace] = useState<Space | undefined>(spaceHelper.getSpace())
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }
  const saveSpaceGroup = async (space: SpaceGroup | undefined) => {
    if (space) {
      setCurrentSpaceGroup(space)
      spaceHelper.setSpaceGroup(space)
    } else {
      spaceHelper.removeSpaceGroup()
    }
  }

  const saveSpace = async (space: Space | undefined) => {
    if (space) {
      let spaceTemp = {...space} as Space
      if (spaceTemp) {
        const countries = await getCountriesBySpace(
          'items_per_page=999&page=1&active=true&spaceId=' + space?.spaceId
        )
        if (countries?.data) spaceTemp.countries = countries.data
      }
      setCurrentSpace(spaceTemp)
      spaceHelper.setSpace(spaceTemp)
    } else {
      spaceHelper.removeSpace()
    }
  }
  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    saveSpaceGroup(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        logout,
        currentSpace,
        saveSpace,
        currentSpaceGroup,
        saveSpaceGroup,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, setCurrentUser} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          const {data} = await getUserByToken(apiToken)
          if (data) {
            setCurrentUser(data)
          }
        }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (auth && auth.api_token) {
      requestUser(auth.api_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
