import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {EnumRoles, useAuth} from '../../../../app/modules/auth'

export function AsideMenuSpace() {
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/spaces'
        title='Dashboard'
        fontIcon='bi-speedometer fs-2'
        bsTitle='Dashboard'
        className='py-2'
      />
      <AsideMenuItem to='/statistics' title='Estatisticas' fontIcon='bi-percent fs-2' className='py-2' />
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/billing', '/Caixa']}
            title='Faturação'
            fontIcon='bi-wallet fs-2'
          >
            <AsideMenuItem to='/billing' title='Faturar' bsTitle='Faturar' hasBullet={true} />
            <AsideMenuItem to='/Caixa' title='Caixa' bsTitle='Caixa' hasBullet={true} />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/clients', '/historyTemplates', '/Segments']}
            title='Clientes'
            fontIcon='bi-people fs-2'
          >
            <AsideMenuItem
              to='/clients'
              title='Lista Clientes'
              bsTitle='Lista Clientes'
              hasBullet={true}
            />
            {currentUser &&
              currentUser.roles &&
              currentUser?.roles?.filter(
                (x) =>
                  x === EnumRoles.Administrador ||
                  x === EnumRoles.AdministradorEspacos ||
                  x === EnumRoles.Marketing ||
                  x === EnumRoles.FaturacaoGerente
              ).length > 0 && (
                <AsideMenuItem
                  to='/historyTemplates'
                  title='Templates de Históricos'
                  bsTitle='Templates de Históricos'
                  hasBullet={true}
                />
              )}

            <AsideMenuItem to='/Segments' title='Segments' bsTitle='Segments' hasBullet={true} />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={[
              '/galleriesPage',
              '/editSpace',
              '/Country',
              '/editSocialMediaSpace',
              '/SpaceTranslationsCountries',
            ]}
            title='Espaço'
            fontIcon='bi-pencil fs-2'
          >
            <AsideMenuItem to='/editSpace' title='Editar' bsTitle='Editar' hasBullet={true} />
            <AsideMenuItem
              to='/editSocialMediaSpace'
              title='Social Media'
              bsTitle='Social media'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/galleriesPage'
              title='Galerias'
              bsTitle='Galerias'
              hasBullet={true}
            />
            <AsideMenuItem to='/Country' title='Paises' bsTitle='Paises' hasBullet={true} />
            <AsideMenuItem
              to='/SpaceTranslationsCountries'
              title='Idiomas'
              bsTitle='Idiomas'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Vouchers
        ).length > 0 && (
          <AsideMenuItem
            to='/vouchers'
            title='Vouchers'
            fontIcon='bi-boxes fs-2'
            bsTitle='Vouchers'
            className='py-2'
          />
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Campanhas
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/campaigns', '/Campaigns/CampaignsTypes']}
            title='Campanhas/Ações'
            fontIcon='bi-speedometer fs-2'
          >
            <AsideMenuItem to='/campaigns' title='Campanhas' bsTitle='Campanhas' hasBullet={true} />
            <AsideMenuItem
              to='/Campaigns/CampaignsTypes'
              title='Tipo Campanhas'
              bsTitle='Tipo Campanhas'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Emails
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/EmailSended', '/EmailConfigs']}
            title='Emails'
            fontIcon='bi-envelope fs-2'
          >
            <AsideMenuItem
              to='/EmailSended'
              title='Histórico de envio de emails'
              bsTitle='Histórico de envio de emails'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/EmailConfigs'
              title='Configs Email'
              bsTitle='Configs Email'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Sms
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/SmsTemplates']}
            title='Sms'
            fontIcon='bi-buildings fs-2'
          >
            <AsideMenuItem
              to='/SmsTemplates'
              title='Templates Sms'
              bsTitle='Templates Sms'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Notificacoes
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/NotificationsTemplate']}
            title='Notificações'
            fontIcon='bi-buildings fs-2'
          >
            <AsideMenuItem
              to='/NotificationsTemplate'
              title='Templates Notificação'
              bsTitle='Templates Notificação'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}

      <AsideMenuItem
        to='/sairEspaco'
        title='Sair do espaço'
        fontIcon='bi-x-circle fs-2'
        bsTitle='Sair do espaço'
        className='py-2'
        isLogOutSpace={true}
      />
    </>
  )
}
