import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItem} from './AsideMenuItem'
import {EnumRoles, useAuth} from '../../../../app/modules/auth'

export function AsideMenuSpaceGroup() {
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboardSpaceGroup'
        title='Dashboard'
        fontIcon='bi-speedometer fs-2'
        bsTitle='Dashboard'
        className='py-2'
      />

      <AsideMenuItem to='/spaces' title='Espaços' fontIcon='bi-house fs-2' className='py-2' />
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.FaturacaoGerente ||
            x === EnumRoles.FaturacaoFuncionario
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/clients', '/historyTemplates', '/Segments']}
            title='Clientes'
            fontIcon='bi-people fs-2'
          >
            <AsideMenuItem
              to='/clients'
              title='Lista Clientes'
              bsTitle='Lista Clientes'
              hasBullet={true}
            />
            <AsideMenuItem to='/Segments' title='Segments' bsTitle='Segments' hasBullet={true} />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/portefolioPage', '/editSpace', '/editSocialMediaSpace', '/appconfigs']}
            title='Grupo'
            fontIcon='bi-pencil fs-2'
          >
            <AsideMenuItem to='/editSpace' title='Editar' bsTitle='Editar' hasBullet={true} />
            <AsideMenuItem to='/appconfigs' title='App Configs' bsTitle='App Configs' hasBullet={true} />
            <AsideMenuItem
              to='/portefolioPage'
              title='Portefolio'
              bsTitle='Portefolio'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/editSocialMediaSpace'
              title='Social Media'
              bsTitle='Social media'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Vouchers
        ).length > 0 && (
          <AsideMenuItem
            to='/vouchers'
            title='Vouchers'
            fontIcon='bi-boxes fs-2'
            bsTitle='Vouchers'
            className='py-2'
          />
        )}
        {currentUser &&
          currentUser.roles &&
          currentUser?.roles?.filter(
            (x) =>
              x === EnumRoles.Administrador ||
              x === EnumRoles.AdministradorEspacos ||
              x === EnumRoles.Marketing ||
              x === EnumRoles.Codes
          ).length > 0 && (
            <AsideMenuItemWithSubMain
              to=''
              toArray={['/codes', '/Codes/Offers', '/Codes/configs']}
              title='Codigos Promocionais'
              fontIcon='bi-speedometer fs-2'
            >
              <AsideMenuItem
                to='/Codes/Offers'
                title='Gerir Ofertas de Códigos'
                bsTitle='Gerir Ofertas de Códigos'
                hasBullet={true}
              />
              <AsideMenuItem
                to='/Codes/Configs'
                title='Activar / Configurar Codigos'
                bsTitle='Activar / Configurar Codigos'
                hasBullet={true}
              />
              <AsideMenuItem to='/codes' title='Lista Códigos' bsTitle='Lista Códigos' hasBullet={true} />
            </AsideMenuItemWithSubMain>
          )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Campanhas
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/campaigns', '/Campaigns/CampaignsTypes']}
            title='Campanhas/Ações'
            fontIcon='bi-speedometer fs-2'
          >
            <AsideMenuItem to='/campaigns' title='Campanhas' bsTitle='Campanhas' hasBullet={true} />
            <AsideMenuItem
              to='/Campaigns/CampaignsTypes'
              title='Tipo Campanhas'
              bsTitle='Tipo Campanhas'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Emails
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/EmailSended', '/EmailConfigs']}
            title='Emails'
            fontIcon='bi-envelope fs-2'
          >
            <AsideMenuItem
              to='/EmailSended'
              title='Histórico de envio de emails'
              bsTitle='Histórico de envio de emails'
              hasBullet={true}
            />
            <AsideMenuItem
              to='/EmailConfigs'
              title='Configs Email'
              bsTitle='Configs Email'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Sms
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/SmsTemplates']}
            title='Sms'
            fontIcon='bi-buildings fs-2'
          >
            <AsideMenuItem
              to='/SmsTemplates'
              title='Templates Sms'
              bsTitle='Templates Sms'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      {currentUser &&
        currentUser.roles &&
        currentUser?.roles?.filter(
          (x) =>
            x === EnumRoles.Administrador ||
            x === EnumRoles.AdministradorEspacos ||
            x === EnumRoles.Marketing ||
            x === EnumRoles.Notificacoes
        ).length > 0 && (
          <AsideMenuItemWithSubMain
            to=''
            toArray={['/NotificationsTemplate']}
            title='Notificações'
            fontIcon='bi-buildings fs-2'
          >
            <AsideMenuItem
              to='/NotificationsTemplate'
              title='Templates Notificação'
              bsTitle='Templates Notificação'
              hasBullet={true}
            />
          </AsideMenuItemWithSubMain>
        )}
      <AsideMenuItem
        to='/sairEspaco'
        title='Sair do Grupo'
        fontIcon='bi-x-circle fs-2'
        bsTitle='Sair do Grupo'
        className='py-2'
        isLogOutSpaceGroup={true}
      />
    </>
  )
}
