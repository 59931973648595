import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuDefault() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        title='Home'
        fontIcon='bi-speedometer fs-2'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-2'
      />
      <AsideMenuItem
        to='/spaces'
        title='Grupos Espaços'
        fontIcon='bi-house fs-2'
        className='py-2'
      />
      {currentUser?.roles?.includes('Administrador') && (
        <>
          <AsideMenuItem
            to='/campaigns/CampaignsTypes'
            title='Tipos ações'
            fontIcon='bi-speedometer fs-2'
            bsTitle='Campanhas'
            className='py-2'
          />
          <AsideMenuItem
            to='/users'
            title='Utilizadores'
            fontIcon='bi-people fs-2'
            className='py-2'
          />
          <AsideMenuItem to='/Country' title='Paises' fontIcon='bi-globe fs-2' className='py-2' />
        </>
      )}
    </>
  )
}
